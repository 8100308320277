<template>
  <div class="content-container">
    <div class="small-logo-container">
      <img src="/images/webclip.png" alt="Logo" class="small-logo">
    </div>
    <div class="content" ref="content">Routing you to roll <span class="code">{{ code }}</span>, hold tight</div>
    <!-- Add GIF below the code text -->
    <img src="/images/dance.gif" alt="Loading..." class="loading-gif">
  </div>
</template>

<script>
export default {
  props: {
    code: String
  },
  mounted() {
    const content = this.$refs.content;
    content.style.opacity = 0;
    content.style.transform = 'scale(0.8)'; // Start at 80% size
    setTimeout(() => {
      content.style.opacity = 1;
      content.style.transform = 'scale(1)'; // Grow to 100%
      content.style.transition = 'all 1.1s ease';
    }, 100);
  }
}
</script>

<style>
body, html {
  margin: 0;
  height: 100%;
  background-color: black;
  overflow: hidden; /* Prevent scrolling */
}

.content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1; /* Ensure it's above the GIF */
}

.small-logo-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2; /* Above content and GIF */
}

.small-logo {
  height: 3vh; /* Use viewport units for responsive height */
  width: auto;
}

.content {
  color: white;
  font-size: 32px;
  transition: opacity 2s ease, transform 2s ease;
  text-align: center; /* Ensure text is centered */
  padding: 0 10px; /* Add padding for smaller screens */
  z-index: 2; /* Above the GIF */
}

.code {
  color: #ff6300;
}

.loading-gif {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Cover the entire area without losing aspect ratio */
  z-index: 1; /* Below content but above everything else */
  opacity: 0; /* Start transparent */
  animation: fadeInGif 5s ease-in forwards; /* Apply fade-in animation */
}

/* Media Queries for smaller devices like iPhones */
@media (max-width: 600px) {
  .content {
    font-size: 5vw; /* Increase font size a bit on small screens */
  }

  .small-logo {
    height: 4vh; /* Slightly larger logo on smaller screens */
  }

  .loading-gif {
    max-width: 100%; /* Ensure it covers the full width */
  }
}

@keyframes fadeInGif {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.3;
  }
}
</style>